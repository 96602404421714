.badge-primary-light {
    @extend .badge-primary;
    background-color: $badge-bg;
    color: $primary; }

.badge-light {
    @extend .badge-info;
    background-color: $gray-300;
    color: $tertiary; }

.badge-success {
    @extend .badge-success;
    background-color: $success-light;
    color: $success; }

.badge-warning {
    @extend .badge-warning;
    background-color: $warning;
    color: $white; }
