.slideout {
  &-menu {
    position: fixed;
    bottom: 0;
    z-index: $zindex-sticky;
    width: 300px;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    &-left {
      left: 0; }
    &-right {
      right: 0;
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15); } }

  &-transition {
    @if $enable-transitions {
      transition: $drawer-transition; } }
  &-panel {
    position:relative {}
    z-index: 1;
    will-change: transform; }

  // &-open,
  // &-open body,
  // &-open &-panel
  //   overflow: hidden

  &-open &-menu {
    // @if $enable-transitions
    //   transition: $drawer-transition
    transform: translateX(0); } }
