.avatar {
	color: $white;
	background-color: $gray-500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	border-radius: 50%;
	height: 48px;
	width: 48px; }

.avatar img {
	width: 100%;
	border-radius: 50%; }

.avatar + .avatar-content {
	display: inline-block;
	margin-left: .75rem; }
.avatar-lg {
	width: 58px;
	height: 58px;
	font-size: $font-size-sm; }

.avatar-sm {
	width: 38px;
	height: 38px;
	font-size: $font-size-sm; }

.verified-avatar {
	@extend .position-relative;
	& > .verified-badge {
		@extend .position-absolute;
		top: calc(100% - 1rem);
		right: 0.5rem; } }
