// .input-group-text
//     transition: $input-transition


.input-group-custom {
    @if $enable-rounded {
        border-radius: $input-border-radius; }
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    @if $enable-shadows {
        box-shadow: $input-group-box-shadow; }
    @if $enable-transitions {
        transition: $input-transition; }
    &.is-invalid {
        border-color: $red!important; }
    &.is-valid {
        border-color: $green!important; }
    .form-control {
        box-shadow: none;
        border: none;
        background: none; // fix border crop issue
        height: $input-height-inner;
        &:not(:last-child) {
            @extend .pr-2; } }
    .input-group-text {
        font-size: $font-size-xl;
        padding: $input-padding-y $input-padding-x*1.4;
        color: $input-group-addon-color;
        background-color: transparent;
        border: none; }
    .form-control:not(:first-child) {
        border-left: 0;
        padding-left: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit; }
    .form-control:not(:last-child) {
        border-right: 0;
        padding-right: 0; }
    &.outlined {
        @extend .shadow-none;
        border-color: $input-outlined-border-color;
        &.disabled {
            border-color: $input-outlined-border-color; } }
    &.underlined {
        @extend .shadow-none;
        @extend .rounded-0;
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        border-color: $input-outlined-border-color;
        &.disabled {
            border-color: $input-outlined-border-color;
            background-color: $input-disabled-bg; } }
    &.focused {
        @if $enable-transitions {
            transition: $input-transition; }
        border-color: $input-group-addon-focus-color;
        background-color: $input-focus-bg; }
    &.disabled {
        background: $input-disabled-bg;
        border-color: $input-disabled-bg;
        box-shadow: none;
        .input-group-text {
            color: $input-placeholder-color; }
        .form-control {
            background: none; } }
    &:input {
        outline: 0; } }



//Autocomplete sass
.input-group-custom {
    .dropdown-item:hover {
        color: $dropdown-link-hover-color;
        background: $dropdown-link-hover-bg; }
    .bootstrap-autocomplete {
        @extend .w-100;
        @extend .mt-2;
        left: 0 !important;
        .dropdown-item {
            &:hover {
                color: $dropdown-link-hover-color;
                background: $dropdown-link-hover-bg; } } } }
.bootstrap-autocomplete {
    @extend .overflow-auto;
    max-height: 230px; }

//phone field
.input-group-custom {
    .iti {
        width: calc(100% - 2.9rem); } }
