$breakpoints: ('small':  ( min-width:  767px ),'medium': ( min-width:  992px ),'large':  ( min-width: 1200px ),'x-large':  ( min-width: 1600px )) !default;
// todo breakpoints remove
.zindex-sticky {
   z-index: $zindex-sticky; }
.sticky {
   position: -webkit-sticky;
   position: $header-position;
   top: 0;
   @extend .zindex-sticky; }
.underlined-link {
    text-decoration: underline;
    &:hover {
        text-decoration: none; } }
.font-primary {
   font-family: $font-family-base; }

.font-alternate {
    font-family: $font-family-alt !important; }
