.nav-tabs-custom {
    border-bottom: none;
    .nav-item {
        .nav-link {
            font-family: $font-family-sans-serif;
            color: $tertiary;
            border: none;
            outline: none;
            &:hover {
                background-color: $nav-tabs-hover-bg; }
            &:active {
                background-color: $nav-tabs-active-bg; }
            &.active {
                font-weight: $font-weight-semi-bold;
                border-bottom: $border-width*3 solid $primary;
                color: $primary;
                background: $nav-tabs-selected-bg; } } } }
.nav-tabs-custom-browse {
    @extend .nav-tabs-custom;
    border-left: none;
    .nav-item {
        .nav-link {
            &.active {
                @include media-breakpoint-up(xl) {
                    border-bottom: none;
                    border-left: $border-width*3 solid $primary; } } } } }

.nav-tabs-custom-filters {
    @extend .nav-tabs-custom;
    border-left: none;
    .nav-item {
        .nav-link {
            &.active {
                    border-bottom: none;
                    border-left: $border-width*3 solid $primary; } } } }

.navbar {
    background-color: $navbar-bg; }
.shadow-nav {
    @if $enable-shadows {
        box-shadow: $navbar-shadow; } }
