.btn-secondary:disabled {
    background-color: $input-disabled-bg; }


//icon button

.btn-inner--icon {
    position: absolute;
    height: 100%;
    line-height: 1;
    border-radius: 0;
    text-align: center;
    margin: 0;
    width: 4em; }
.btn-label {
    position: relative;
    .btn-inner--icon {
        text-align: center; }
    .btn-inner--icon svg , .btn-inner--icon i {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 1.5em; }
    .btn-inner--icon:not(:last-child) {
        left: 0;
        top: 0;
        & ~ .btn-inner--text {
            padding-left: 2em; } }
    &.btn-hidden-label {
        @include media-breakpoint-down('sm') {
            .btn-inner--icon:not(:last-child) {
                & ~ .btn-inner--text {
                    padding-left: 1em; } } } }
    .btn-inner--icon:last-child {
        right: 0;
        top: 0;
        & ~ .btn-inner--text {
            padding-right: 2em; } } }

// Button with invisible label
.btn-hidden-label {
    @extend .rounded-circle;
    @extend .btn-label;
    width: 3rem;
    height: 3rem;
    .btn-inner--text {
        @extend .font-weight-normal;
        visibility: hidden;
        position: absolute; // due to requirement change
        white-space: nowrap; }
    .btn-inner--icon {
        position: static; }
    .btn-inner--icon svg , .btn-inner--icon i {
        width: auto;
        transform: translate(0);
        top: 0; }
    &:hover, &:focus {
        background-color: $gray-400;
        .btn-inner--text {
            visibility: visible; } } }
