.select-group {
    @extend .position-relative;
    &.disabled {
        color: $input-placeholder-color;
        .select-inner--icon {
            color: $input-placeholder-color; } }
    .select-inner--icon {
        @extend .position-absolute;
        line-height: 1;
        border-radius: 0;
        text-align: center;
        margin: 0;
        width: 1.3em;
        top: 50%;
        left: $btn-padding-x;
        transform: translateY(-50%);
        z-index: 9;
        color: $primary; }
    .select-custom-btn {
        padding-left: 2*$btn-padding-x + 1.2rem; } }
.select-custom {
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-left: 4rem;
    &::-ms-expand {
         display: none; }
    &.show {
        .select-custom-btn {
            &:after {
                @if $enable-transitions {
                    transition: $input-transition; }
                transform: rotate(180deg); } } }
    &.form-control {
        border: $input-border-width solid $input-border-color;
        @if $enable-shadows {
            box-shadow: $select-box-shadow; }
        &.disabled {
            box-shadow: none; } }
    &.outlined {
        border: 0;
        .select-custom-btn {
            box-shadow: none;
            border: $input-border-width solid $input-outlined-border-color;
            &:focus {
                border-color: $primary; } }
        &.show {
            .select-custom-btn {
                border-color: $primary; } } }
    .dropdown-menu {
        margin-top: map-get($spacers, 2);
        max-height : 60vh !important; }
    .select-custom-btn {
        color: $input-color;
        font-weight: $font-weight-normal;
        // background: $white
        // box-shadow: none !important
        // height: add($input-line-height * 1.156em, $input-padding-y * 2)
        &.disabled {
            background-color: $input-disabled-bg;
            color: darken($input-placeholder-color, 15%);
            &:after {
                content: url("data:image/svg+xml,<svg fill='#{url-encoded-color(darken($input-placeholder-color, 15%))}' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 -4 24 24'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>"); } }
        &:after {
            @if $enable-transitions {
                transition: $input-transition; }
            content: url("data:image/svg+xml,<svg fill='#{url-encoded-color($primary)}' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 -4 24 24'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>");
            border: none !important; } }
    .dropdown-item:hover {
        color: $dropdown-link-hover-color;
        background: $dropdown-link-hover-bg; } }
.bootstrap-select {
    .dropdown-toggle {
        outline: none !important;
        &:focus {
            outline: none !important; }
        &::after {
            margin-left: 1em; } }
    .dropdown-item:focus {
        outline: 0; }
    &.show-tick {
        .dropdown-menu {
            .selected {
                span {
                    &.check-mark {
                        top: 50%;
                        transform: translateY(-60%); } } } } } }

.dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    @if not $enable-shadows {
        box-shadow: $box-shadow-sm; } }

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: $input-placeholder-color; }
.nav-link {
    &.dropdown-toggle {
        @extend .pr-4;
        &:after {
            border-top: none;
            border-right: none;
            border-bottom: 0;
            border-left: none;
            content: url("data:image/svg+xml,<svg fill='#{url-encoded-color($gray-700)}' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 -4 24 24'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>");
            position: absolute;
            top: 50%;
            transform: translateY(-56%);
            right: 0.5rem; }
        &[aria-expanded="true"] {
            &:after {
                transform: translateY(-46%) rotate(180deg); } } } }
