
/*FOOTER*/

.main-footer .list-inline {
    // max-width: 75rem
    padding-left: .9375rem;
    padding-right: .9375rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: .9375rem;
    padding-top: .9375rem;
    // background-color: $card-bg
    // border-bottom: 1px solid $gray-200
    @extend .border-top;
    display: flex;
    justify-content: center; }



.main-footer .list-inline a {
    font-size: $spacer;
    @extend .font-alternate;
    color: $gray-600;
    line-height: 1.618;
    padding: 10px; }

.main-footer p {
    font-size: 10px;
    line-height: 1.5;
    color: $gray-500; }

.main-footer img {
    height: 62px !important; }

.footer-copyright {
    @extend .text-tertiary-light;
    font-size: $font-size-xs; }
