.vr-mobile-view {
    display: block; }

.volunteer-registration-heading {
    font-weight: $font-weight-bold;
    font-size: $h3-font-size;
    color: $gray-800; }

.volunteer-registration-description {
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    color: $gray-800; }

.horizontal-line {
    background-color: $gray-400;
    height: 1px;
    margin: 30px 0; }

.fields-are-required {
    color: $gray-500;
    font-size: $font-size-xs;
    margin: 30px 0; }

.form-check-inline {
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    color: $gray-800; }

.form-description {
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    line-height: 20px;
    font-family: sans-serif;
    color: #646A84;
    margin-right: 3rem; }

.vr-radio-label:after , .vr-radio-label:before {
    opacity: 0;
    position: fixed;
    width: 0; }

input[type="radio"]:checked + .vr-radio-label {
    padding: $table-cell-padding;
    background: rgba($primary,.1);
    border: $border-width solid $primary;
    border-radius: $border-radius;
    font-weight: $font-weight-normal;
    font-size: $font-size-xs;
    color: $primary; }

.vr-radio-label {
    cursor: pointer;
    align-items: center;
    padding: $table-cell-padding;
    background: $secondary;
    border: $border-width solid $gray-400;
    border-radius: $border-radius;
    font-weight: $font-weight-normal;
    font-size: $font-size-xs;
    color: $gray-800; }

.volunteer-form-caption, .volunteer_type_field_label {
    font-size: $font-size-md;
    color: $gray-800;
    margin-bottom: 5px; }

.vertical-line {
    width: 0px;
    border-right: 1px solid $gray-400;
    margin: 0 5px; }

.vertical-line-focused {
    width: 0px;
    border-right: 2px solid $primary; }

.form-div-main {
    justify-content: flex-start; }

.form-div1 {
    width: 650px;
    max-width: 650px; }

.form-div-half {
    width: 325px;
    max-width: 325px; }

.select2-container--default .select2-selection--multiple {
    width: 650px;
    max-width: 650px; }

.form-div2 {
    width: 380px;
    max-width: 380px; }

.vr-radio {
    padding: 0;
    margin: 2px; }

.vr-checkbox-label {
    font-weight: $font-weight-normal;
    color: $gray-800; }

#vr-checkbox > .custom-control-input ~ .custom-control-label:after {
    cursor: pointer;
    border-color: $primary; }

#vr-checkbox > .custom-control-input:not(:disabled) ~ .custom-control-label:hover {
    border-color: $primary; }

.v-reg-banner-bottom {
    height: 293px;
    background: $gray-300;
    border: 1px solid $gray-300;
    text-align: justify; }

.v-reg-banner-content {
    padding: 4rem 1rem; }

.v-reg-banner-heading {
    font-weight: $headings-font-weight;
    font-size: $h5-font-size;
    color: $gray-800; }

.v-reg-banner-description {
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    color: $gray-800; }

.race-ethnicity-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

//Volunteer registration Mobile view

@media only screen and (max-width: 1199px) {
    .form-div-half {
        max-width: 270px; }

    .select2-container--default .select2-selection--multiple {
        max-width: 570px; } }

@media only screen and (max-width: 1000px) {
    .form-div-half {
        max-width: 200px; }

    .select2-container--default .select2-selection--multiple {
        max-width: 440px; } }

@media only screen and (max-width: 750px) {
    .v-reg-banner-bottom {
        height: auto;
        padding: 2rem; }

    .form-div-main {
        max-width: 500px; }

    .form-div-half {
        max-width: 130px; }

    .select2-container--default .select2-selection--multiple {
        max-width: 500px; }

    .form-div1 {
        max-width: 500px; }

    .form-div2 {
        max-width: 260px; } }

@media only screen and (max-width: 415px) {

    .race-ethnicity-block {
        display: flex;
        flex-direction: column; }

    .vr-mobile-view {
        display: none; }

    .vertical-line {
        display: none; }

    .form-description {
        display: none; }

    .form-div1 {
        width: 100%; }

    .form-div2 {
        display: none !important; }

    .form-div-half {
        width: 100%;
        margin: none;
        max-width: 100%; }

    .form-div-main {
        max-width: 100%; }

    .select2-container--default .select2-selection--multiple {
        max-width: 100%; } }

@media only screen and (max-width: 392px) {
    .form-div-main {
        max-width: 100%; } }

@media only screen and (max-width: 320px) {
    .g-recaptcha {
         max-width: 230px;
         overflow: hidden; }

    .form-div-main {
        max-width: 240px; }

    .form-div-half {
        max-width: 110px; }

    .select2-container--default .select2-selection--multiple {
        max-width: 230px; }

    .form-div1 {
        max-width: 230px; }

    .form-div2 {
        max-width: 70px; } }
