//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/
@import 'variables/cwatch/_third_party_theme_vars.sass';
@import 'variables/cwatch/_theme_vars.scss';
@import 'fonts/_fonts.sass';
@import 'modules/_config.sass';

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~@fortawesome/fontawesome-free/css/all.css";
// @import "~animate.css/animate.css"
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "~raty-js/lib/jquery.raty.css";
@import "~select2/dist/css/select2.css";
@import "~aos/dist/aos.css";
// SASS MIXINS
//----------------------------------*/

@import 'mixins/_color.sass';
@import 'mixins/_position.sass';
@import 'mixins/_breakpoints.sass';

//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/_header.sass';
@import 'partials/_nav.sass';
@import 'partials/_sidebar.sass';
@import 'partials/_main.sass';
@import 'partials/_footer.sass';

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/

@import 'modules/_buttons.sass';
@import 'modules/_inputs.sass';
@import 'modules/_lists.sass';
@import 'modules/_typography.sass';
@import 'modules/_misc.sass';
@import 'modules/_app.sass';
@import 'modules/_spinners';

// Enhancement modules
@import 'modules/_breakpoints.sass';
@import 'modules/_print.sass';

//----------------------------------*\
// VENDOR
//----------------------------------*/
@import 'components/_components.sass';

// Custom component overide
@import 'components/_custom-chip.sass';
@import 'components/_bootstrap-select.sass';
@import 'components/_google-autocomplete.sass';
@import 'components/_avatar.sass';
@import 'components/_slideout.sass';
@import 'components/_slick.sass';
@import 'components/_intl-tel-input.scss';
@import 'components/_select2';
@import 'components/_volunteer-registration.sass';

@import 'vendor/_external.sass';
