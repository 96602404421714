
//responsive font
@include media-breakpoint-down(xl) {
  html {
    font-size: 14px; } }

@include media-breakpoint-down(lg) {
  html {
    font-size: 13px; } }

@include media-breakpoint-down(md) {
  html {
    font-size: 12px; } }

.line-height {
  &-sm {
    line-height: 1.3; } }


/// html
// html
//     font-family: 'Merriweather Sans'
//     font-weight: 400
///Headings
.text-tertiary-light {
    color: $tertiary-light; }
a {
  &.text-tertiary-light:hover {
      color: $tertiary; } }

// .nav-link
//     font-family: $font-family-alt
.card-data {
    @extend .my-3;
    font-family: $font-family-alt; }

.card-data > .card-stats {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start; }
legend {
    margin: 0; }
.light-text {
  color: $black-50 !important; }

.white-space-pre-wrap {
  white-space: pre-wrap; }

.two-lines-only {
  line-height: normal;
  max-height: 2.6em;
  overflow: hidden; }

.four-lines-only {
  line-height: normal;
  max-height: 5.1em;
  overflow: hidden; }
