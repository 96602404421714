.form-check-inline {
    display: inline-flex; } //overide bootstrap
// Custom controls
.custom-control-input:not(:disabled)~.custom-control-label {
    // Background-color and (when enabled) gradient
    &::before {
        // border: $custom-control-indicator-border-width solid $custom-control-indicator-border-color
        @if $enable-transitions {
            transition: $input-transition; } }

    &:hover::before {
        border-color: $component-hover-border-color; } }

.custom-control-input:disabled~.custom-control-label {
    &:before {
        border-color: $custom-control-label-disabled-color;
        background-color: transparent; } }


.custom-control-input:not(:disabled) {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color; } }

// Checkboxes */
.custom-checkbox {
    .custom-control-input~.custom-control-label {
        &::after {
            // background-image: $custom-checkbox-indicator-icon-checked
            // width: $custom-control-gutter + $custom-control-indicator-size
 } }            // height: $custom-control-gutter + $custom-control-indicator-size



    .custom-control-input:checked~.custom-control-label {
        &::before {
            background: $custom-control-indicator-checked-bg;
            color: $custom-control-indicator-checked-bg;
 } } }            // border-color: $custom-control-indicator-checked-border-color

// Radios
.custom-radio {
    .custom-control-input~.custom-control-label {
        &::before {
 }            // top: 0.2rem //fix for oval shaped radio temporary

        &::after {
            // background-image: $custom-radio-indicator-icon-checked
            color: $custom-control-indicator-checked-border-color; } }


    .custom-control-input:checked~.custom-control-label {
        &::before {
            // top: 0.2rem //fix for oval shaped radio temporary
            background: none;
            border-color: $custom-control-indicator-checked-border-color; } } }

//custom icon radio
.icon-radio {
    @extend .d-inline-flex;
    @extend .align-items-center;
    input {
        display: none;
        ~label {
            @extend .mb-0;
            cursor: pointer;
            .radio-icon {
                @extend .d-inline-flex;
                @extend .align-items-center;
                @extend .justify-content-center;
                @extend .rounded-circle;
                color: $white;
                width: $custom-control-indicator-size;
                height: $custom-control-indicator-size;
                font-size: $font-size-xxs;
                vertical-align: text-bottom;
                &:not(:disabled) {
                    background: $gray-500; }
                svg {
                    fill: currentColor; } }
            .radio-label {
                @extend .ml-2;
                @extend .d-inline-flex; }
            &:hover {
                .radio-icon {
                    background: $gray-600; } } }

        &:checked {
            ~label {
                .radio-icon {
                    background: $tertiary; } } }
        &:disabled {
            ~label {
                .radio-icon {
                    background: $gray-300; } } } } }

