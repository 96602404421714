.form-control {
    &.outlined {
        @extend .shadow-none;
        border-color: $input-outlined-border-color;
        &:focus {
            border-color: $primary; }
        &:disabled {
            border-color: $input-outlined-border-color; }
        &.is-invalid {
            border-color: $red; }
        &.is-valid {
            border-color: $green; } }
    &.underlined {
        @extend .shadow-none;
        @extend .rounded-0;
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        border-color: $input-outlined-border-color;
        &:focus {
            border-color: $primary; }
        &:disabled {
            border-color: $input-outlined-border-color; }
        &.is-invalid {
            border-color: $red; }
        &.is-valid {
            border-color: $green; } } }
.google-autocomplete-input {
    .input-icon {
        @extend .position-absolute;
        @extend .text-primary;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        padding: $input-padding-y*1.3 $input-padding-x*1.45;
        font-size: $font-size-xl; }
    .form-control {
        padding-left: $font-size-xl+(2.5*$input-padding-x); } }

.vol-datepicker {
    display: inline;
    width: 31%;
    margin-right: 5px; }
