$enable-caret:              true;
$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          false;
$enable-transitions:        true !default;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Color system

$white:    #fff !default; 
$gray-100: #f8f9fa;
$gray-200: #f5f5f5; //cardColor
$gray-300: #f0f1f2; // borderColor
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057; 
$gray-800: #343a40; 
$gray-900: #212529; 
$black:    #000 !default;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #3369d6;
$indigo:  #5603ad;
$purple:  #801096;
$pink:    #e36397;
$red:     #E04F5F;
$orange:  #ff3b00;
$yellow:  #B86E1A;
$yellow-light: #EEA95B;
$green:   #1E8D52;
$green-light:#20B566;
$featured-green: #00c759;
$teal:    #73e9ef;
$cyan:    #2bffc6;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "yellow-light": $yellow-light,
  "green":      $green,
  "green-light": $green-light,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "light":      $gray-400,
  "lighter":    $gray-200,
  "gray-dark":  $gray-800
), $colors);

$primary:      #0180A6 !default;
$primary-light: #EDFBFF !default;
$primary-dark: #3572a9 !default;
$secondary:     #fff !default;
$tertiary:      #333 !default;
$success:       $green;
$success-light: #cdf2de !default; 
$info:          $primary;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-500;
$dark:          $gray-800;

$facebook:      #3b5999;
$twitter:       #1da1f2;
$google-plus:   #dd4b39; 
$instagram:     #e4405f;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$slack:         #3aaf85;
$dribbble:      #ea4c89;


$theme-colors: ();
$theme-colors: map-merge((
  "primary":      $primary,
  "primary-light":$primary-light,
  "primary-dark": $primary-dark,
  "secondary":    $secondary,
  "tertiary":     $tertiary,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "light":        $light,
  "dark":         $dark
), $theme-colors);

$brand-colors: ();
$brand-colors: map-merge((
  "facebook":     $facebook,
  "twitter":      $twitter,
  "google-plus":  $google-plus,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "slack":        $slack,
  "dribbble":     $dribbble
), $theme-colors);


// Action colors

$favorite-color: $yellow;
$like-color: $blue;
$love-color: $red;


// Body

$body-bg: $white !default;
$body-color: $tertiary !default;
$black-50: rgba($black, .65) !default;


// Links

$link-color:                $primary !default;
$link-decoration:           none !default;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline !default;
$link-visited-color:      #707070;

 
// Spacing

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  'sm': ($spacer * 2),
  'md': ($spacer * 4),
  'lg': ($spacer * 6),
  'xl': ($spacer * 8)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);

//shadows
$box-shadow-sm:               0 0 .45rem rgba($black, .1) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1.1rem 1.71rem rgba($black, .12) !default;

// Components

$line-height-xl:              1.5;
$line-height-lg:              1.5;
$line-height-md:              1;
$line-height-sm:              0.75;
 
$border-width:                .0625rem;
$border-color:                $gray-300;

$border-radius:               .375rem !default;
$border-radius-xl:            $border-radius;
$border-radius-lg:            $border-radius * 1.86 !default;
$border-radius-md:            $border-radius * 1.3 !default;
$border-radius-sm:            $border-radius * 0.66 !default;

$component-active-color:        $white;
$component-active-bg:           $primary;
$component-active-border-color: $primary;

$component-hover-color:        $gray-300;
$component-hover-bg:           $white;
$component-hover-border-color: $primary;

$caret-width:                 .3em;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;


// Fonts
$font-name: 'Merriweather';
$font-name-alt: 'Roboto Condensed';
$font-family-base: $font-name, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-alt: $font-name-alt, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * 1);
$font-size-md:                ($font-size-base * 1.12);
$font-size-xs:                ($font-size-base * .875);
$font-size-xxs:                ($font-size-base * .75);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.6;

$h1-font-size:                $font-size-base * 3.06;
$h2-font-size:                $font-size-base * 2.44;
$h3-font-size:                $font-size-base * 1.94;
$h4-font-size:                $font-size-base * 1.56;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      ($spacer / 2);
$headings-font-family:        inherit;
$headings-font-weight:        500;
$headings-line-height:        1.5;
$headings-color:              inherit !default;

// $headings-margin-bottom:      ($spacer / 2);
// $headings-font-family:        inherit;
// $headings-font-weight:        600;
// $headings-line-height:        1.3;
// $headings-color:              $gray-800;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-500;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.1);

$hr-border-color:             rgba($black, .05);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$list-inline-padding:         1.5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;


// Icons

$icon-size: 3rem;
$icon-size-xl: 5rem;
$icon-size-lg: 4rem;
$icon-size-sm: 2rem;


// Tables

$table-cell-padding:          .75rem;
$table-cell-padding-sm:       .5rem;

$table-bg:                    transparent;
$table-accent-bg:             $gray-200;
$table-hover-bg:              rgba($black, .075);
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $gray-300;

$table-head-bg:               $gray-200;
$table-head-color:            $gray-700;

$table-dark-bg:               $gray-900;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($gray-900, 7.5%);
$table-dark-color:            $body-bg;
$table-striped-order:         even;

// Buttons + Forms

$input-btn-padding-y:         .7rem;
$input-btn-padding-x:         1rem;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       0;
$input-btn-focus-color:       rgba($component-active-bg, 1);
$input-btn-focus-box-shadow:  0 4px 10px 0 rgba(0, 0, 0, 0.04);
//$input-btn-focus-box-shadow:  none;

$input-btn-padding-y-sm:      .55rem;
$input-btn-padding-x-sm:      1rem;
$input-btn-font-size-sm:      $font-size-xs;
$input-btn-line-height-sm:    $line-height-md;

$input-btn-padding-y-lg:      .95rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-padding-y-xl:      1.15rem;
$input-btn-padding-x-xl:      1rem;
$input-btn-line-height-xl:    $line-height-xl;

$input-btn-border-width:      $border-width;


// Buttons 

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x + 0.5;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-padding-y-xl:            $input-btn-padding-y-xl;
$btn-padding-x-xl:            $input-btn-padding-x-xl;
$btn-line-height-xl:          $input-btn-line-height-xl;

$btn-border-width:            0;

$btn-font-weight:             600;
$btn-box-shadow:              $box-shadow;
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;

$btn-active-box-shadow:       none;
$btn-link-disabled-color: lighten($tertiary, 50%);


// Forms

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-padding-y-xl:                    $input-btn-padding-y-xl;
$input-padding-x-xl:                    $input-btn-padding-x-xl;
$input-line-height-xl:                  $input-btn-line-height-xl;

$input-border-radius:                   $border-radius;
$input-border-radius-xl:                $border-radius-xl;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-200 !default;

$input-color:                           $gray-700 !default;
$input-border-color:                    transparent !default;
$input-outlined-border-color:           $gray-400 !default;
$input-border-width:                    $input-btn-border-width*2;
$input-box-shadow:                      0px 6px 12px 0px rgba(0,0,0,0.08);

$input-focus-bg:                        $white !default;
$input-focus-border-color:              $primary;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               lighten($tertiary, 50%) !default;

$input-height-border:                   $input-border-width * 2;

$input-transition:                      $transition-base;


// Input groups

$input-group-addon-color:               $primary;
$input-group-addon-bg:                  $white;
$input-group-addon-border-color:        $white;

$input-group-addon-focus-color:               $primary;
$input-group-addon-focus-bg:                  $white;
$input-group-addon-focus-border-color:        $primary;
// Custom forms

$custom-control-gutter:                 0.25rem;
$custom-control-spacer-x:               1rem;
$custom-control-label-disabled-color:   lighten($tertiary, 50%);
$custom-control-indicator-size:         1.25rem;

$custom-control-indicator-bg:                   $input-bg;
$custom-control-indicator-border-width:         $input-border-width;
$custom-control-indicator-border-color:         $tertiary;
$custom-checkbox-indicator-border-radius:       0;
$custom-control-indicator-box-shadow:           none;

$custom-control-indicator-hover-color:         $component-hover-color;
$custom-control-indicator-hover-bg:            $component-hover-bg;
$custom-control-indicator-hover-border-color:  $component-hover-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-bg:            $component-active-bg;
$custom-control-indicator-active-border-color:  $component-active-border-color;
$custom-control-indicator-active-box-shadow:    none;

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-bg:           $component-active-bg;
$custom-control-indicator-checked-border-color: $component-active-border-color;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 24 24'><path fill='#{$custom-control-indicator-checked-color}' d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z'/></svg>");
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><circle r='2' cx='4' cy = '4' fill='#{$custom-control-indicator-checked-bg}'/></svg>");
$custom-control-indicator-bg-size: 100% 100%;


// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $input-border-radius;
$btn-border-radius-xl:        $input-border-radius-xl;
$btn-border-radius-lg:        $input-border-radius-lg;
$btn-border-radius-sm:        $input-border-radius;

// No UI Slider

$noui-target-bg:                  $gray-300;
$noui-target-thickness:           $custom-control-indicator-border-width;
$noui-target-border-radius:       4px;
$noui-target-border-color:        darken($border-color, 3%);

$noui-slider-connect-bg:          $primary;
$noui-slider-connect-disabled-bg: #b2b2b2;

$noui-handle-bg:                  #fff;
$noui-handle-border-color:        #d9d9d9;
$noui-handle-border-radius:       100%;

$noui-origin-border-radius:       2px;

// Dropdown

$dropdown-bg:                       $white !default;
$dropdown-border-width:             0;
$dropdown-border-color:             rgba($black, .15);
$dropdown-border-radius:            $border-radius;


// Navs 

$nav-link-padding-y:                .25rem;
$nav-link-padding-x:                $spacer * 2;
$nav-link-color:                    $gray-700 !default;
$nav-link-disabled-color:           $gray-600;

$nav-pills-border-radius:           $border-radius;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

// header
$header-position: 'relative' !default;
// Navbar
$navbar-bg: $white !default;
$navbar-shadow: 0 .1rem .4rem rgba($black, .05) !default;
$navbar-transition:                 all .15s linear;
$navbar-padding-y:                  .5rem;
$navbar-padding-x:                  1rem;
$navbar-nav-link-padding-x:         1rem;
// $navbar-brand-font-size:             1rem !default;
$navbar-dark-bg:                    transparent;
$navbar-dark-hover-bg:              rgba(255, 255, 255, .1);
$navbar-dark-active-bg:             rgba(255, 255, 255, .1);
$navbar-dark-color:                 rgba($white, .5);
$navbar-dark-hover-color:           rgba($white, .75);
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .25);
//$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' path stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-dark-toggler-border-color:  transparent;

$navbar-light-color:                rgba($tertiary, .8) !default;
$navbar-light-hover-color:          rgba($tertiary, .9) !default;
$navbar-light-active-color:         rgba($tertiary, 1) !default;
$navbar-light-disabled-color:       rgba($tertiary, .3);
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'> <path stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($tertiary, .0);


// Alerts

$alert-padding-y: 1rem;
$alert-padding-x: 1.5rem;
$alert-border-radius: $border-radius-md;

$alert-bg-level: -10;
$alert-border-level: -10;
$alert-color-level: 0;
$alert-progress-height: 4px;

// Close s

$close-font-size:                   $font-size-base * 1.5;
$close-font-weight:                 $font-weight-bold;
$close-bg:                          rgba(0, 0, 0, .3);
$close-hover-bg:                    rgba(0, 0, 0, .5);
$close-color:                       rgba(255, 255, 255, .6);
$close-text-shadow:                 none;


// Popovers

$popover-font-size:                 $font-size-sm;
$popover-bg:                        $white;
$popover-max-width:                 276px;
$popover-border-width:              1px;
$popover-border-color:              rgba($black, .05);
$popover-border-radius:             $border-radius-lg;
$popover-box-shadow:                0px .5rem 2rem 0px rgba($black, .2);

$popover-header-bg:                 $popover-bg;
$popover-header-color:              $headings-color;
$popover-header-padding-y:          .75rem;
$popover-header-padding-x:          .75rem;

$popover-body-color:                $body-color;
$popover-body-padding-y:            $popover-header-padding-y;
$popover-body-padding-x:            $popover-header-padding-x;

$popover-arrow-width:               1.5rem;
$popover-arrow-height:              .75rem;
$popover-arrow-color:               $popover-bg;

$popover-arrow-outer-color:         transparent;


// Badges

$badge-font-size:                   100%;
$badge-font-weight:                 $font-weight-bold;
$badge-padding-y:                   .35rem;
$badge-padding-x:                   .375rem;
$badge-border-radius:               $border-radius;
$badge-bg:                          $primary-light;

$badge-pill-padding-x:              .65em;
$badge-pill-border-radius:          10rem;

// Pagination

// $pagination-padding-y:              .5rem;
// $pagination-padding-x:              .75rem;
// $pagination-padding-y-sm:           .25rem;
// $pagination-padding-x-sm:           .5rem;
// $pagination-padding-y-lg:           .75rem;
// $pagination-padding-x-lg:           1.5rem;
// $pagination-line-height:            1.25;

$pagination-color:                  $gray-600 !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           0; 
$pagination-border-color:           $gray-300;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;

$pagination-hover-color:            $gray-600;
$pagination-hover-bg:               $gray-300; 
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $tertiary;
$pagination-active-bg:              $gray-300 !default;
$pagination-active-border-color:    $white;

$pagination-disabled-color:         $gray-500; 
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300;


// Cards

$card-spacer-y:                     1.25rem;
$card-spacer-x:                     1.5rem; 
$card-border-width:                 $border-width * 0;
$card-border-radius:                $border-radius-lg;
$card-border-color:                 rgba($black, .05);
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       $gray-200;
$card-cap-color:                    $tertiary !default;
$card-bg:                           $gray-200 !default;
$card-box-shadow: $box-shadow-lg !default;
$card-hover-box-shadow: $box-shadow-lg !default;
$card-transition: $transition-base !default;

$card-img-overlay-padding:          1.25rem;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;

// Modals

$modal-inner-padding:         1.5rem;

$modal-md:                    700px !default;
$modal-sm:                    560px !default;

// $modal-dialog-margin:         .5rem;
// $modal-dialog-margin-y-sm-up: 1.75rem;

//$modal-title-line-height:           $line-height-base;

$modal-content-bg:               $white !default;
$modal-content-border-color:     rgba($black, .2);
$modal-content-border-width:     1px;
$modal-content-border-radius:   $border-radius;
$modal-content-box-shadow-xs:    0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:           $black !default;
$modal-backdrop-opacity:      .3;
$modal-header-border-color:   $gray-200;
$modal-footer-border-color:   $modal-header-border-color;
$modal-header-border-width:   $modal-content-border-width;
$modal-footer-border-width:   $modal-header-border-width;
$modal-header-padding:        1.25rem;


// Footer

$footer-bg: $gray-200 !default;
$footer-color: $gray-600;
$footer-link-color: $gray-600;
$footer-link-hover-color: $primary;


//Dropdown colors 
$dropdown-link-active-color: $input-color;
$dropdown-link-active-bg: lighten($tertiary, 76%);
$dropdown-link-disabled-color: $input-placeholder-color;
$dropdown-caret-color: $primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: $primary-light;
$dropdown-item-padding-y: .75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-box-shadow: $input-box-shadow;

//Nav Tabs
$nav-tabs-border-radius: none;

//Chip colors 
$chip-bg: $gray-200 !default;
$chip-color: $tertiary !default;
$chip-active-bg: $primary !default;
$chip-active-color: $white !default;
$chip-naked-border-color: #e6e6e6 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

$zindex-dropdown:          1020 !default;
$zindex-sticky:            1000 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;



$input-group-box-shadow: $input-box-shadow;
$input-group-focus-border-color: $primary;
$tertiary-light: lighten($tertiary, 30%);
$select-box-shadow: $input-group-box-shadow;
$width-default: 100%;
$font-family-sans-serif: 'Merriweather', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: 'Roboto Condensed', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-weight-semi-bold: 600;
$drawer-transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
// Custom
$header-height: 122px;

$workspace-header-height: 4.23rem;
$annotation-header-height: 3.68rem;

//Annotation Variables
$private-annotation-bg: #f6d2e4;
$public-annotation-bg: #d9d8da;
$annotation-focused-bg:  #00ffa1;
$annotation-adder-toolbar: rgba($black, 0.85);


//Slick Theme Variables
$slick-arrow-color: $tertiary;
$slick-next-character: url('data:image/svg+xml,<svg fill="currentColor" height="20" width="20" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon points="13.854,48.707 37.561,25 13.854,1.293 12.439,2.707 34.732,25 12.439,47.293  "/></g></svg>');
$slick-prev-character: url('data:image/svg+xml,<svg fill="currentColor" height="20" width="20" style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon points="37.561,47.293 15.267,25 37.561,2.707 36.146,1.293 12.439,25 36.146,48.707  "/></g></svg>');

// Spinners
$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

$spinner-width-lg:        2.5rem !default;
$spinner-height-lg:       $spinner-width-lg !default;
$spinner-border-width-lg: .24em !default;

//Font Awesome Family
$font-awesome: 'Font Awesome 5 Free';
$font-awesome-font-weight-solid: 900;
$font-awesome-font-weight-regular: 500;

//caret
$caret-size: 10px;
$caret-position: 1.5rem;

// Tooltips

$tooltip-font-size:                 $font-size-xxs;
$tooltip-color:                     $white;
$tooltip-bg:                        $tertiary;
$tooltip-border-radius:             0px;
$tooltip-arrow-color:               $tooltip-bg;
$tooltip-padding-y:                 .5rem;
$tooltip-padding-x:                 .75rem;

//Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'><path fill='#{$gray-600}' d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/></svg>") !default;

//nav tabs
$nav-tabs-hover-bg: $primary-light;
$nav-tabs-selected-bg: $primary-light;
$nav-tabs-active-bg: lighten($primary-light, 5%);

//collapse background
$collapse-bg: $body-bg;
$collapse-hover-bg: lighten($primary-light, 5%);
$collapse-description-bg: $body-bg !default;
$collapse-heading-color: #fff !default;
$collapse-expanded-heading-color: #000!default;
$collapse-hover-bg: lighten($primary, 60%) !default;
$collapse-border-width: 0px !default;
$collapse-border-color: $border-color !default;
$collapsed-bg: $primary !default;
$unicode-for-accordion: \f107 !default;
$unicode-for-collapsed-accordion: \f106 !default;
$collapse-margin-bottom: .25rem !default;
$tooltip-box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.4);
$accordion-padding-y:.5rem !default;
$accordion-padding-x:.5rem !default;


