.select2 {
    &-container--default {
        .select2-selection--multiple {
            box-shadow: none;
            border-radius: $input-border-radius;
            .select2-selection__rendered {
                padding: 0 $input-padding-x; } }
        &:not(.select2-container--open, .select2-container--focus) {
            .select2-selection--multiple {
                border: $input-border-width solid $primary !important; } }
        &.select2-container--open, &.select2-container--focus {
            .select2-selection--multiple {
                border: $input-border-width solid $primary !important; } } } }
