.custom-chip {
    @extend .font-alternate;
    @extend .position-relative;
    font-size: $font-size-sm;
    margin: map-get($spacers, 1);
    label {
        margin: 0;
        height: 100%; }
    input[type="checkbox"] {
        display: none; }
    input[type="checkbox"] + label, > div {
        @if $enable-transitions {
            transition: $input-transition; }
        cursor: pointer;
        border-radius: 50px;
        background-color: $chip-bg;
        color: $chip-color;
        padding: map-get($spacers, 1) map-get($spacers, 3) map-get($spacers, 1) map-get($spacers, 3);
        display: inline-block;
        user-select: none;
        // &:after
        //   content: url("data:image/svg+xml,<svg width='10' height='10' fill-rule='evenodd' clip-rule='evenodd' fill='#{url-encoded-color($tertiary)}' xmlns='http://www.w3.org/2000/svg'  viewBox = '0 0 24 24'><path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z'/></svg>")
        //   position: absolute
        //   right: map-get($spacers, 2)
        //   top: 50%
 }        //   transform: translateY(-50%)
    input[type="checkbox"]:checked + label, > div.active {
        @if $enable-transitions {
            transition: $input-transition; }
        background-color: $chip-active-bg;
        color: $chip-active-color;
        // &:after
 } }        //   content: url("data:image/svg+xml,<svg width='10' height='10' fill-rule='evenodd' clip-rule='evenodd' fill='#{url-encoded-color($white)}' xmlns='http://www.w3.org/2000/svg'  viewBox = '0 0 24 24'><path d='M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z'/></svg>")

.custom-chip {
    input[type="text"] {
        outline: 0;
        width: 90px;
        padding: 0; }
    .naked-chip {
        border: 1px solid darken($chip-bg, 30%);
        background-color: transparent;
        margin: -1px;
        cursor: default;
        user-select: auto;
        & > .add-custom-condition {
            &:not(:focus) {
                transition: width 0.2s ease-in 0.5s; } } }
    .focussed-input-chip {
        @if $enable-transitions {
            transition: $input-transition; }
        border-color: $input-group-addon-focus-color;
        background-color: $chip-bg;
        & > .add-custom-condition {
            &:focus {
                width: 120px;
                transition: width 0.2s ease-out; } } }
    .chip-action {
        display: block;
        margin-left: map-get($spacers, 1);
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,<svg width='10' height='10' fill-rule='evenodd' clip-rule='evenodd' fill='#{url-encoded-color($tertiary)}' xmlns='http://www.w3.org/2000/svg'  viewBox = '0 0 24 24'><path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z'/></svg>");
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 50%;
        background-color: $chip-bg;
        top: calc(50% - 10px);
        right: 5px;
        &:hover {
            background-color: $gray-500; } }
    .input-chip {
        display: flex;
        padding-right: 5px;
        align-items: center; }
    .tag-chip {
        background: $gray-200;
        color: $tertiary-light; }
    &.chip-with-button {
        @extend .position-relative;
        label {
            @extend .pr-1; }
        button {
            @extend .rounded-circle;
            @extend .btn;
            @extend .pl-1;
            @extend .pr-2;
            @extend .py-0;
            font-size: $font-size-xxs;
            color: currentColor; } } }
a.custom-chip {
    @extend .text-decoration-none;
    @extend .d-inline-block; }

.custom-chip.naked-chip {
    input[type="checkbox"] + label {
        border: 1px solid $chip-naked-border-color;
        background-color: transparent;
        margin: -1px;
        user-select: auto; }
    input[type="checkbox"]:checked + label {
        background-color: $chip-active-bg;
        border-color: $chip-active-bg; } }

