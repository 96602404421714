.main_header {
        letter-spacing: 0.03em;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: $zindex-fixed;
        .nav-link {
                @extend .position-relative;
                @extend .align-items-center;
                display: flex;
                text-transform: capitalize;
                font-size: 1.1rem;
                height: 4rem;
                &:before {
                        content: "";
                        position: absolute;
                        height: 3px;
                        background: $white;
                        border-radius: 3px 3px 0 0;
                        width: 100%;
                        bottom: 0;
                        left: 0; }
                &.active {
                        position: relative;
                        &:before {
                                background: $primary; } }
                &:hover {
                        background: $gray-200;
                        &:before {
                                background: $tertiary-light; } } } }
.header-spacer {
        height: 4rem;
        background-color: $white; }
#workspace-container {
        height: 4rem; }
#workspace {
        width: 100%;
        z-index: $zindex-sticky; }

@include media-breakpoint-down('xs') {
        .cw-logo {
                margin-right: 0;
                img {
                        height: 35px; } } }

.line {
        background-color: #f0f1f2;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 100vw;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.06); }

.cw-logo img {
        max-width: 100%; }

