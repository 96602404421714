.card {
    &-ribbon {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $featured-green;
        text-transform: uppercase;
        font-size: $font-size-xs;
        font-family: $font-family-alt;
        color: $white;
        padding: 2px map-get($spacers, 2);
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        @if $enable-shadows {
            @extend .shadow; }
        &::before {
            content: "";
            width: 20px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 100%;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{url-encoded-color($featured-green)}" viewBox="0 0 30 30" width="100%" height="100%" transform="rotate(90)"><path d="M23,27l-8-7l-8,7V5c0-1.105,0.895-2,2-2h12c1.105,0,2,0.895,2,2V27z"/></svg>');
            background-size: 300%;
            background-position: 0; }
        &-primary {
            @extend .card-ribbon;
            background-color: $primary;
            &::before {
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{url-encoded-color($primary)}" viewBox="0 0 30 30" width="100%" height="100%" transform="rotate(90)"><path d="M23,27l-8-7l-8,7V5c0-1.105,0.895-2,2-2h12c1.105,0,2,0.895,2,2V27z"/></svg>');
                background-size: 300%;
                background-position: 0; } } }
    &-elevated {
        @if $enable-shadows {
            box-shadow: $card-box-shadow;
            &:hover {
                box-shadow: $card-hover-box-shadow; }
            transition: $card-transition; }
        @if not $enable-shadows {
            box-shadow: none; } }
    &-grounded {
        @if $enable-shadows {
            box-shadow: $card-box-shadow; }
        @if not $enable-shadows {
            box-shadow: none; } }
    &-header {
        .card-title {
            color: $card-cap-color; } }
    &-wave {
        @extend .position-absolute;
        @extend .w-100;
        right: 0;
        bottom: -1px; } }

.volunteer-img {
    max-width: 100%; }
.card-volunteer {
    box-shadow: none; }
@include media-breakpoint-down(xl) {
    .card-img--height-fixed {
        max-height: 120px; } }
@include media-breakpoint-between(sm, xl) {
    .card-volunteer {
        width: 82%;
        margin-left: auto; } }



.custom-popover {
    display: none;
    z-index: $zindex-popover;
    & > .custom-popover-container {
        max-width: 400px;
        max-height: 500px;
        overflow: auto; } }

.hover-popover {
    display: none;
    z-index: $zindex-popover; }

.mw {
    &-25 {
        max-width: 25%; }
    &-50 {
        max-width: 50%; }
    &-65 {
        max-width: 65%; }
    &-75 {
        max-width: 75%; } }
.min-w {
    &-0 {
        min-width: 0; } }

.dropdown-toggle::after {
    top: 0.1rem;
    position: relative;
    transition: transform 0.2s linear; }

.dropdown-toggle-up::after {
    transform: rotate(-180deg);
    transition: transform 0.2s linear; }
