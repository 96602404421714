//3 panel container css
.container-panel-lg {
    @extend .container;
    @include media-breakpoint-up(xxl) {
        max-width: 100%; } }
.col-left-aside, .col-right-aside, .col-right-xl-aside {
    @extend .col-auto;
    min-width: 200px; }
.col-center, .col-center-sm {
    @extend .col; }
.row-panel-lg {
    @extend .row;
    @extend .justify-content-center;
    // flex: 1
    // flex-direction: column ->fixes iphone issue
    .col-center-sm {
        max-width: 100%; }
    @include media-breakpoint-up(xl) {
        flex-direction: row;
        .col-left-aside, .col-right-aside {
            width: 13.33%; }
        .col-aside-lg {
            width: 23.33%; }
        .col-center-sm {
            max-width: map-get($container-max-widths, md); } }
    @include media-breakpoint-up(xxl) {
        .col-right-xl-aside {
            width: 13.33%; }
        .col-center {
            max-width: map-get($container-max-widths, xl); }
        .col-center-sm {
            max-width: map-get($container-max-widths, lg); } } }
.filter_list {
    .d-none {
        display: none !important; } }
.collapse {
    &-caret-scrollable {
        @extend .overflow-auto;
        max-height: 250px;
        &-toggle {
            @extend .d-block;
            @extend .position-relative;
            outline: 0;
            &:after {
                @extend .small;
                @if $enable-transitions {
                    transition: $transition-base; }
                position: absolute;
                right: 0;
                top: calc(50% - 8px);
                content: '\f078'; //angle down unicode for font awesome
                font-weight: $font-awesome-font-weight-solid;
                font-family: $font-awesome; }
            &[aria-expanded="true"] {
                &:after {
                    @if $enable-transitions {
                        transition: $transition-base; }
                    transform: rotate(180deg); } } } }
    &-description {
        background: $collapse-description-bg; }
    &-caret-add {
        &-toggle {
            @extend .text-decoration-none;
            @extend .d-block;
            @extend .position-relative;
            margin-bottom: $collapse-margin-bottom;
            padding: $accordion-padding-y $accordion-padding-x;
            outline: 0;
            background-color: $collapse-bg;
            border: $collapse-border-width solid $collapse-border-color;
            color: $collapse-heading-color;
            &:after {
                @if $enable-transitions {
                    transition: $transition-base; }
                position: absolute;
                right: 0.5rem;
                top: calc(50% - 0.85rem);
                content: unquote("\"#{ $unicode-for-accordion }\""); //+ unicode for font awesome
                font-weight: $font-awesome-font-weight-solid;
                font-family: $font-awesome;
                padding: 0 0.5rem;
                border: none;
                font-size: 1.275rem; }
            &[aria-expanded="true"] {
                background: $collapsed-bg;
                border: None;
                color: $collapse-expanded-heading-color;
                &:after {
                    @if $enable-transitions {
                        transition: $transition-base; }
                    content:   unquote("\"#{ $unicode-for-collapsed-accordion }\""); //- unicode for font awesome
                    font-weight: $font-awesome-font-weight-solid;
                    font-family: $font-awesome; } }
            &:hover {
                background: $collapse-hover-bg; } } } }


//Popover Carets
.caret {
    &-top {
        &-right {
            margin-top: $caret-size;
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-bottom: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                right: $caret-position;
                top: -$caret-size; } }
        &-left {
            margin-top: $caret-size;
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-bottom: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                left: $caret-position;
                top: -$caret-size; } }
        &-center {
            margin-top: $caret-size;
            &:before {
                position: absolute;
                height: $caret-size * 1.4;
                width: $caret-size * 1.4;
                content: '';
                left: calc(50% - #{$caret-size * 1.4 / 2});
                top: -$caret-size * 1.4 / 2;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                transform: rotate(45deg);
                z-index: -1; }
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-bottom: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                left: calc(50% - #{$caret-size});
                top: -$caret-size; } } }
    &-right {
        &-bottom {
            margin-right: $caret-size;
            &:after {
                position: absolute;
                border-top: $caret-size solid transparent;
                border-bottom: $caret-size solid transparent;
                border-left: $caret-size solid $primary;
                content: '';
                right: -$caret-size;
                bottom: $caret-position;
                height: 0;
                width: 0; } }
        &-top {
            margin-right: $caret-size;
            &:after {
                position: absolute;
                border-top: $caret-size solid transparent;
                border-bottom: $caret-size solid transparent;
                border-left: $caret-size solid $primary;
                right: -$caret-size;
                top: $caret-position;
                height: 0;
                width: 0;
                content: ''; } } }
    &-bottom {
        &-left {
            margin-bottom: $caret-size;
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-top: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                left: $caret-position;
                bottom: -$caret-size; } }

        &-right {
            margin-bottom: $caret-size;
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-top: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                right: $caret-position;
                bottom: -$caret-size; } }
        &-center {
            margin-bottom: $caret-size;
            &:before {
                position: absolute;
                height: $caret-size * 1.4;
                width: $caret-size * 1.4;
                content: '';
                left: calc(50% - #{$caret-size * 1.4 / 2});
                bottom: -$caret-size * 1.4 / 2;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                transform: rotate(45deg);
                z-index: -1; }
            &:after {
                position: absolute;
                border-left: $caret-size solid transparent;
                border-right: $caret-size solid transparent;
                border-top: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                left: calc(50% - #{$caret-size});
                bottom: -$caret-size; } } }
    &-left {
        &-bottom {
            margin-left: $caret-size;
            &:after {
                position: absolute;
                border-top: $caret-size solid transparent;
                border-bottom: $caret-size solid transparent;
                border-right: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                bottom: $caret-position;
                left: -$caret-size; } }
        &-top {
            margin-left: $caret-size;
            &:after {
                position: absolute;
                border-top: $caret-size solid transparent;
                border-bottom: $caret-size solid transparent;
                border-right: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                top: $caret-position;
                left: -$caret-size; } }
        &-center {
            margin-left: $caret-size;
            &:before {
                position: absolute;
                height: $caret-size * 1.4;
                width: $caret-size * 1.4;
                content: '';
                top: calc(50% - #{$caret-size * 1.4 / 2});
                left: -$caret-size * 1.4 / 2;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                transform: rotate(45deg);
                z-index: -1; }
            &:after {
                position: absolute;
                border-top: $caret-size solid transparent;
                border-bottom: $caret-size solid transparent;
                border-right: $caret-size solid $primary;
                height: 0;
                width: 0;
                content: '';
                top: calc(50% - #{$caret-size});
                left: -$caret-size; } }
        &-light {
            &:after {
                border-right-color: $gray-200; } } } }
