
.main_header {
    height: 4rem; }

.page-footer {
    @extend .font-alternate;
    background-color: $footer-bg; }
#hero {
    @include media-breakpoint-up('md') {
        max-height: calc(100vh - #{$header-height});
        min-height: 620px; } }

.hero-bg {
    @extend .position-absolute;
    @extend .w-100;
    bottom: 0;
    left: 0;
    z-index: -99; }
.footer-waves {
    z-index: -99; }
.translated-bg {
    @extend .position-relative;
    z-index: 9;
    display: inline-block;
    &:after {
        @extend .position-absolute;
        content: '';
        left: 10%;
        top: -20%;
        width: 100%;
        height: 100%;
        background: $primary-light;
        z-index: -1; } }

.text-blur {
    text-shadow: 2px 2px 7px $body-color;
    color: transparent;
    pointer-events: none;
    user-select: none; }

.annotation {
    &-meta {
        font-size: 90%; }
    &-drawer {
        @extend .bg-white;
        top: $workspace-header-height;
        bottom: 0;
        min-width: 200px;
        width: calc(100% - 4.6rem) !important;
        @include media-breakpoint-up(lg) {
            width: 28% !important; }
        @include media-breakpoint-up(xl) {
            width: 24% !important; }
        &__main {
            @extend .position-relative;
            height: 100vh; }
        &__content {
            height: calc(100% - #{$workspace-header-height} - #{$annotation-header-height});
            overflow-y: auto;
            overflow-x: hidden; }
        &--visible &__content {
            if $enable-transitions {
                transition: opacity 1s cubic-bezier(0.4, 0.0, 0.2, 1); }
            opacity: 1; }
        &__toggle {
            @extend .position-absolute;
            width: 5rem;
            height: 3.5rem;
            top: 6rem;
            left: -5rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: medium;
            border-right-color: black; }
        &__helper-guide {
            top: 5.4rem;
            right: calc(100% + 5rem); }
        &__share-annotations {
            @extend .position-absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($black, 0.075); }
        &__share {
            @extend .position-relative, .bg-white, .rounded, .p-3, .mx-5, .mt-5, .rounded-lg;
            @if $enable-shadows {
                @extend .shadow; }
            &-content {
                @extend .rounded, .p-3;
                @extend .font-alternate;
                background-color: $gray-200; } } } }


.private-annotation, .public-annotation {
    background: $private-annotation-bg;
    cursor: pointer;
    &-focused, &-focused & {
        background:$annotation-focused-bg !important {} }
    &-orphan {
        cursor: inherit!important; } }
.public-annotation {
    background: $public-annotation-bg; }

.annotation-adder-btn {
    @extend .rounded;
    padding: 0.75rem; }

.annotation-adder-toolbar {
    background: $white;
    z-index: 999;
    &.caret-top-center {
        &:after {
            border-bottom-color: $white; } }
    &.caret-bottom-center {
        &:after {
            border-top-color: $white; } } }

.annotation-guide {
    @extend  .position-fixed;
    background-color: rgba(0,0,0,0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1029;
    & > img {
        width: 100%; }
    & > p {
        font-size: 90%; }
    .annotation-guide-content {
        max-width: 500px;
        margin: 3rem auto 0;
        .annotation-guide-icon {
            min-height: 100px;
            max-height: 170px; } }
    .close-guide-corner {
        transform: scale(1.4); } }

#workspace-guide-created {
    width: 260px;
    top: 65px;
    right: 2rem;
    font-size: 90%;
    z-index: 1020; }

.trial-definitions-active {
    .trial-definition-term, .trial-definition-term .private-annotation, .trial-definition-term .public-annotation {
        border-bottom: 2px dotted $primary;
        cursor: pointer; } }
.trial-definition-content {
    width: 100%;
    z-index: 1021;
    & .definition-content, & .definition-source {
        font-size: 90%;
        font-weight: 400; } }

.trial-definition-inline, .hover-popover {
    width: 300px;
    max-width: 100%;
    z-index: 1021;
    & .arrow {
        &, &::before {
            position: absolute;
            width: 18px;
            height: 18px;
            z-index: -1; }
        &::before {
            content: '';
            transform: rotate(45deg);
            background: $white;
            @extend .shadow-sm; } }
    &[data-popper-placement^='top'] {
        & > .arrow {
            bottom: -9px; } }
    &[data-popper-placement^='bottom'] {
        & > .arrow {
            top: -9px; } }
    &[data-popper-placement^='left'] {
        & > .arrow {
            right: -9px; } }
    &[data-popper-placement^='right'] {
        & > .arrow {
            left: -9px; } } }

.trial-definition-inline {
    & .arrow {
        &::before {
            background: $gray-200; } } }

.hover-popover {
    width: 191px !important;
    box-shadow: $tooltip-box-shadow;
    margin: 1rem; }

.navigator-account-logo {
    height: 1.5em;
    width: auto;
    object-fit: contain; }

// search listing

.media .popover-target:hover {
    &::before {
        display: none; } }

#search_result > main {
    & > div:nth-of-type(2):hover {
        .media .popover-target {
            position: relative;
            &::before {
                content: "What is this?";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: $spacer/2;
                font-size: $font-size-xxs;
                white-space: nowrap;
                color: $tertiary;
                background-color: $white;
                box-shadow: $box-shadow-sm;
                padding: $spacer/3 $spacer;
                border-radius: $border-radius; } } } }

.search-item--title {
    max-height: 3em;
    overflow: hidden; }
.search-item--link {
    color: $tertiary;
    &:hover {
        color: $tertiary; }
    &:visited {
        color: $link-visited-color; } }
.search-item--image {
    @include media-breakpoint-down(sm) {
        width: 100%; } }




.search-item--decription {
    max-height: 3.3em;
    overflow: hidden; }

.media-img-sq {}

.trial-results-img-sq {
    width: 11.25rem;
    max-width: 11.25rem;
    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%; } }

.imagery-icon {
    background-image: url('/static/img/imagery_glyph2.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 300px;
    margin-right: 0.5rem;
    transform: scale(1.3);
    flex-shrink: 0; }



//Trail Details Scrollspy & Navigation
#trial-navigation, #learn-more-navigation {
    top: 6rem;
    display: none;
    z-index: $zindex-sticky - 1;
    @include media-breakpoint-up('xl') {
        display: block; } }
.trial-scrollspy, .learn-more-scrollspy {
    @extend .position-relative;
    @extend .flex-row;
    @extend .flex-xl-column;
    &:before {
        @extend .position-absolute;
        @include media-respond-up('large') {
            content: "";
            top: 1rem;
            bottom: 1rem;
            width: 4px;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="4"><circle cx="2" cy="2" r="2" fill="currentColor" /></svg>');
            background-repeat-x: no-repeat;
            background-position: 50% 25%; } }
    .list-group-item.active {
        color: $tertiary;
        background-color: transparent;
        font-weight: 800; } }
.learn-more-scrollspy:before {
    top: 1.1rem;
    bottom: auto;
    height: 88%; }
.trial-stats {
    @extend .text-muted;
    @extend .font-alternate;
    @extend .py-3;
    span {
        @extend .text-tertiary-light;
        @extend .position-relative;
        padding-right: 1rem;
        &:not(:first-child) {
            padding-left: 1rem;
            &:before {
                content: '.';
                position: absolute;
                left: -0.1rem;
                transform: translateY(-0.25rem); } } } }

.advanced-filter-tabs, .advanced-filter-list {
    max-height: 350px; }

//Show half of 3rd card on similar listings css
@include media-breakpoint-up(xl) {
    .similar-trials-carousel {
        .slick-list {
            padding: 0 20% 0 0; } } }

.blockquote { //extra properties in blockquote
   border-left: 0.18rem solid $gray-400;
   padding-left: 1rem; }
.step {
    @extend .d-flex;
    @extend .flex-wrap;
    &-heading {
        @extend .h1;
        line-height: $line-height-sm; }
    &-title {
        @extend .ml-3;
        @extend .pl-3;
        @extend .py-2;
        @extend .border-left;
        @extend .font-weight-bold;
        @extend .text-tertiary;
        font-size: $font-size-md; }
    &-link {
        @extend .ml-auto;
        @extend .col-auto;
        @extend .px-0; }
    &-wrapper {
        @extend .py-4;
        &:first-of-type {
            border-top: 0.15rem solid $border-color; }
        border-bottom: 0.15rem solid $border-color; }
    &-collapse {
        @extend .text-decoration-none;
        @extend .position-relative;
        @extend .d-block;
        @extend .pr-4;
        &:after {
            font-family: $font-awesome;
            font-weight: $font-awesome-font-weight-solid;
            content: "\f078";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: $font-size-md;
            transition: $transition-base; }
        &[aria-expanded="true"] {
            &:after {
                transition: $transition-base;
                transform: translateY(-50%) rotate(180deg); } }
        &:hover {
            .step-title {
                text-decoration: underline; } } } }
.half-border-bottom {
    @extend .position-relative;
    @extend .pb-3;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 3rem;
        height: 3px;
        background: $primary; } }

.cursor-default {
    cursor: default!important; }

.outline-none {
    outline: none!important; }

//Trial Details Rating CSS
#trial-rating {
    i {
        @extend .mx-2; } }

#annotation-info {
    right: calc(100% + 4.6rem);
    width: 100%;
    top: 0.5rem; }

#graph {
    svg {
        overflow: visible; } }


// New style
// Added by Joef

.main-footer {
    .footer-link {
        padding-left: .9375rem;
        padding-right: .9375rem;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: .9375rem;
        padding-top: .9375rem;
        font-family: 'Roboto Condensed', sans-serif;
        a {
            color: #6c757d; } } }

.navbar-light .navbar-toggler {
    @include media-breakpoint-down(lg) {
        // position: absolute
        // top: 12px
 } }        // right: 10px
.center-cta {
    @include media-breakpoint-down(sm) {
        text-align: center!important;
        display: block!important; } }

.card-volunteer {
    h6 {
        font-size: $h5-font-size; }
    @include media-breakpoint-up(xxl) {
        h6, a, p {
            font-size: 80%; }
        p {
            text-align: center; } }
    @include media-breakpoint-down(lg) {
        width: 100%!important; }
    @include media-breakpoint-down(sm) {
        display: block!important;
        text-align: center!important; } }

.result-item {
    .media-img-sq {}
    & > .card-body {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            .media-body, .card-data {
                padding-left: 1rem;
                padding-right: 1rem; }
            .img-media-wrap {
                position: relative;
                height: 100px;
                width: 100%;
                overflow: hidden; }
            .img-media {
                width: 100%; } } } }



#b_m_c, #b_t {
    .pagination {
        .page-link {
            padding-left: 0.55rem;
            padding-right: 0.55rem;
            font-size: $font-size-sm; } } }
.stat-item {
    background: $gray-200;
    padding: 0.75rem;
    border-radius: $border-radius; }

.list_item-wrap {
    @include media-breakpoint-down(sm) {
        text-align: center; } }

.browse-item-descriptions {
    @include media-breakpoint-up(md) {
        text-align: center; } }
.buttons {
    button {
        @include media-breakpoint-down(sm) {
            margin-right: 50px!important; } } }


.row-trial-detail {
    .sticky-custom {
        @include media-breakpoint-down(md) {
            position: relative!important; } } }


.sticky-wrap {
    position: sticky;
    top: 10px; }

#trial-navigation {
    margin-left: auto;
    max-width: 187px;
    .list-group {
        flex-direction: column!important;
        @include media-breakpoint-down(md) {
            flex-direction: row!important;
            position: relative!important;
            z-index: 10;
            top: -100px; }
        a {
            display: block!important;
            @include media-breakpoint-down(md) {
                display: inline-block!important;
                &:first-child {
                    padding-left: 0!important; } } } } }

.card-trial-detail {
    @include media-breakpoint-down(md) {
        border-top-left-radius: 0;
        border-top-right-radius: 0; } }

#annotation-adder {
    width: 50px;
    position: absolute!important;
    right: 0; }


.sticky-img-wrap {
    @include media-breakpoint-down(md) {
        position: relative;
        height: 100px;
        overflow: visible; }
    img {
        @include media-breakpoint-down(md) {
            object-fit: fill;
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            height: 130px;
            margin-top: 40px; } } }

.annotation-drawer__helper-guide {
    width: 200px!important;
    h6 {
        font-size: 12px; }
    ul {
        padding-left: 20px;
        font-size: 11px; }
    a {
        font-size: 11px; } }
.data-sources {
    @extend .row;
    @extend .justify-content-center;
    @extend .align-items-center;
    opacity: 0.5; }

.similar-trial-heading {
    height: 2.6rem;
    overflow: hidden; }
@include media-breakpoint-down('sm') {
    .slick-prev {
        left: -15px; }
    .slick-next {
        right: -15px; }
    .investigator-site {
        max-width: 50%; } }

.similar-trial-img {
    max-height: 146px; }

.vr-checkbox {
    padding-left: 35px; }

.select2-container {
    width: 100%!important; }

.select2-search--dropdown {
    width: 98%; }

.select2-search__field {
    width: 98%;
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.125rem solid transparent;
    border-radius: 0.375rem;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-in-out; }

.select2-selection--multiple {
    border: 0 !important;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08); }

.select2-dropdown {
    border: 0;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08); }

.select2-results__option--highlighted {
	color: #3c98b5 !important;
	background: #f4fafc !important; }

.select2-selection__choice {
    margin-top: 12px !important; }

.read-more {
    &-section {
        .read-more-link {
            // @extend .position-absolute
            @extend .d-inline-block;
            @extend .mt-2;
            bottom: 0; } }
    &-wrapper {
        @if $enable-transitions {
            transition: min-height 0.15s ease-out; }
        overflow: hidden; } }

.vr-condition-list {
    list-style: none; }
.condition-title {
    min-width: 12.2rem; }

.source-account-logo {
    margin-left: -30px; }

#similar-trials {
    min-height: 15rem; }


#similar-trials .spinner-border {
    margin-left: 48%;
    margin-top: 7rem; }

.trial-thumbnail {
    width: 150px;
    height: 100px;
    object-fit: cover; }
.trial-thumbnail-small {
    width: 80px;
    height: 80px;
    object-fit: cover; }


.trial-result-thumbnail {
    width: 11.25rem;
    height: 11.25rem;
    object-fit: cover; }
@include media-breakpoint-down('sm') {
    .trial-result-thumbnail {
        width: 100%; } }

.data-source-text {
    @extend .position-relative;
    z-index: 9; }

.sign-cta-btn {
    @extend .d-block;
    @include media-breakpoint-up(xxl) {
        color: $white;
        background: $primary;
        box-shadow: $btn-box-shadow;
        border-radius: $btn-border-radius;
        text-decoration: none;
        padding: 0.75rem;
        text-align: center;
        &:focus, &:hover {
            background: darken($primary, 5%);
            color: $white; }
        &:active {
            box-shadow: $btn-focus-box-shadow;
            background: darken($primary, 5%);
            color: $white;
            text-decoration: none; } } }

.eligibility {
    &-read-more-wrapper {
        @extend .position-relative;
        height: 15.5rem;
        overflow: hidden;
        padding-bottom: 3.5rem;
        margin-bottom: 1.5rem;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3.5rem;
            width: 100%;
            background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box; }
        &.expanded {
            @extend .mb-0;
            height: auto;
            padding-bottom: 0;
            &:after {
                height: 0; } } }
    &-not-active {
        .icon-radio, .table-heading {
            @extend .d-none; } } }
#eligibility-read-more-link {
        font-size: 90%; }

.breadcrumb-item:before {
    font-family: $font-awesome;
    font-weight: $font-awesome-font-weight-solid; }

.card-stats {
    li {
        margin-bottom: map-get($spacers, 2); } }
@include media-breakpoint-down(sm) {
    .card-stats {
        li {
            margin-right: map-get($spacers, 2);
            margin-bottom: 0; } } }
.browse-list-item {
    @extend .px-0;
    @extend .list-group-item;
    @extend .d-flex;
    @extend .justify-content-start;
    @extend .align-items-center;
    @extend .rounded-0;
    @extend .border-0;
    .selected-item {
        @extend .font-weight-bold; }
    a.selected-item {
        @extend .underlined-link; } }

.browse-list-loading {
    pointer-events: none;
    opacity: 0.4; }

.sign-up-text {
    @extend .font-weight-bold;
    font-size: $font-size-md; }
.scroll-down {
    &-section {
        left: 50%;
        transform: translateX(-50%);
        bottom: 2.5rem; }
    &-button {
        @extend .btn;
        @extend .btn-secondary;
        @extend .text-primary;
        @extend .rounded-circle;
        @extend .p-0;
        @extend .justify-content-center;
        @extend .align-items-center;
        display: flex;
        height: 2.5rem;
        width: 2.5rem;
        opacity: 0.8;
        font-size: $font-size-xs; } }

.scroll-top-fab {
    @extend  .position-fixed;
    right: 0;
    bottom: 0;
    margin: 0 2rem 2rem 0;
    z-index: 99;
    & > button {
        @extend .btn;
        @extend .btn-primary;
        @extend .rounded-circle;
        @extend .p-0;
        padding: 0;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        line-height: 1;
        box-shadow: $box-shadow;
        &:hover {
            box-shadow: $box-shadow-lg; } } }

.tooltip-icon-text {
    font-size: $font-size-xxs; }

.icon_eligibility {
    min-width: 1rem !important;
    @extend .text-right;
    span {
        width: 3rem !important;
        img {
            width: 1.3rem !important; } } }

.whats-this {
    @extend .position-absolute ,.bg-white ,.rounded;
    cursor: pointer;
    top: -1.2rem;
    box-shadow: $tooltip-box-shadow;
    z-index: 1;
    @include media-breakpoint-down(sm) {
        top: -3rem; } }

.arrow-whats-this {
    @extend .position-absolute ,.bg-white;
    top: 1rem !important;
    height: 14px;
    width: 14px;
    transform: rotate(45deg) !important;
    left: 2rem;
    z-index: -1; }

#infoimagery-information {
    width: 28rem !important;
    margin-bottom: -1.2rem !important;
    left: 8rem !important;
    @include media-breakpoint-down(sm) {
        left: 0rem !important; }

    .arrow {
        margin-left: 3.2rem !important; } }

#preferred_lang_study + .btn-light {
    background-color: inherit;
    border-color: inherit;
    box-shadow: initial; }

#advanced-filter-modal {
        .modal-content {
            max-height: 95vh;
            overflow: auto; } }

.contact_status_action {
    .alert {
        display: block !important; } }
