.pac-container {
    @extend .mt-2;
    @extend .py-2;
    @extend .rounded;
    @extend .overflow-auto;
    @extend .shadow;
    @extend .border-0;
    // max-height: 250px
    font-family: $font-family-sans-serif;
    // &.pac-logo
    //     &::after
    //         display: none
    .pac-item {
        cursor: pointer;
        @extend .dropdown-item;
        .pac-item-query {
            @extend .lead; }
        &.pac-item-selected {
            background-color: $dropdown-link-active-bg; }
        &:hover {
            .pac-item-query {
                color: $primary; } } } }
