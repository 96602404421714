.pagination {
    @extend .font-alternate;
    font-size: $font-size-lg;
    flex-wrap: wrap;
    & > .page-item {
        &:first-child {
            .page-link {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0; } }
        &:last-child {
            .page-link {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0; } }
        .page-link {
            &:hover, &:focus {
                text-decoration: underline; } } }
    &.takeout-pagination {
        & > .page-item {
            &:first-child {
                .page-link {
                    border: none; } }
            &:last-child {
                .page-link {
                    border: none;
                    margin-left: 0; } } } } }
.takeout-browse-pagination {
    @extend .pagination;
    font-size: $font-size-xs;
    & > .page-item {
        .page-link {
            padding: $pagination-padding-y; } } }

.inactive {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #a1a1a1; }
