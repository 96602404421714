@font-face {
  font-family: $font-name;
  font-style: normal;
  font-weight: 300;
  src: url('./Merriweather/merriweather-v21-latin-300.eot');
  src: local('Merriweather Light'), local('Merriweather-Light'), url('./Merriweather/merriweather-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-300.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-300.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-regular - latin */
@font-face {
  font-family: $font-name;
  font-style: normal;
  font-weight: 400;
  src: url('./Merriweather/merriweather-v21-latin-regular.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url('./Merriweather/merriweather-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-regular.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-300italic - latin */
@font-face {
  font-family: $font-name;
  font-style: italic;
  font-weight: 300;
  src: url('./Merriweather/merriweather-v21-latin-300italic.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'), url('./Merriweather/merriweather-v21-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-300italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-300italic.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-700italic - latin */
@font-face {
  font-family: $font-name;
  font-style: italic;
  font-weight: 700;
  src: url('./Merriweather/merriweather-v21-latin-700italic.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'), url('./Merriweather/merriweather-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-700italic.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-italic - latin */
@font-face {
  font-family: $font-name;
  font-style: italic;
  font-weight: 400;
  src: url('./Merriweather/merriweather-v21-latin-italic.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Italic'), local('Merriweather-Italic'), url('./Merriweather/merriweather-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-italic.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-700 - latin */
@font-face {
  font-family: $font-name;
  font-style: normal;
  font-weight: 700;
  src: url('./Merriweather/merriweather-v21-latin-700.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Bold'), local('Merriweather-Bold'), url('./Merriweather/merriweather-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-700.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-700.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-900italic - latin */
@font-face {
  font-family: $font-name;
  font-style: italic;
  font-weight: 900;
  src: url('./Merriweather/merriweather-v21-latin-900italic.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'), url('./Merriweather/merriweather-v21-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-900italic.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-900italic.svg#Merriweather') format('svg') /* Legacy iOS */; }

/* merriweather-900 - latin */
@font-face {
  font-family: $font-name;
  font-style: normal;
  font-weight: 900;
  src: url('./Merriweather/merriweather-v21-latin-900.eot') /* IE9 Compat Modes */;
  src: local('Merriweather Black'), local('Merriweather-Black'), url('./Merriweather/merriweather-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Merriweather/merriweather-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('./Merriweather/merriweather-v21-latin-900.woff') format('woff'), /* Modern Browsers */ url('./Merriweather/merriweather-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Merriweather/merriweather-v21-latin-900.svg#Merriweather') format('svg') /* Legacy iOS */; }

//Roboto Condensed Font
/* roboto-condensed-300 - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: normal;
  font-weight: 300;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-300.eot') /* IE9 Compat Modes */;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url('./Roboto-Condensed/roboto-condensed-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }

/* roboto-condensed-300italic - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: italic;
  font-weight: 300;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.eot') /* IE9 Compat Modes */;
  src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-300italic.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: normal;
  font-weight: 400;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.eot') /* IE9 Compat Modes */;
  src: local($font-name-alt), local('RobotoCondensed-Regular'), url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }

/* roboto-condensed-700 - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: normal;
  font-weight: 700;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-700.eot') /* IE9 Compat Modes */;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url('./Roboto-Condensed/roboto-condensed-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }

/* roboto-condensed-italic - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: italic;
  font-weight: 400;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.eot') /* IE9 Compat Modes */;
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-italic.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }

/* roboto-condensed-700italic - latin */
@font-face {
  font-family: $font-name-alt;
  font-style: italic;
  font-weight: 700;
  src: url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.eot') /* IE9 Compat Modes */;
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto-Condensed/roboto-condensed-v18-latin-700italic.svg#RobotoCondensed') format('svg') /* Legacy iOS */; }
